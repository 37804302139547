import React, { useState } from "react";
import {
  Container,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Box,
  Grid,
  Avatar,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Navbar } from "../components/Navbar";
import Footer from "../components/Footer";
import Community from "../components/Community";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// Validation Schema
const validationSchema = Yup.object().shape({
  profilePic: Yup.mixed()
    .test("fileSize", "File size should be less than 50KB", (value) => {
      return value && value.size <= 50000;
    })
    .required("Profile Picture is required"),
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  college: Yup.string().required("College name is required"),
  branch: Yup.string().required("Branch is required"),
  year: Yup.string().required("Year is required"),
  linkedIn: Yup.string()
    .url("Must be a valid URL")
    .required("LinkedIn Profile is required"),
});

const Perks = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      profilePic: "",
      name: "",
      email: "",
      college: "",
      branch: "",
      year: "",
      linkedIn: "",
    },
  });

  // Open and close dialog handlers
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSize('');
    setName('');
    reset();
  };

  // Submit handler
  const onSubmit = (data) => {
    console.log("Form Data:", data);
    handleClose();
  };

  const schoolData = [
    {
      schoolName: "College A",
      students: [
        {
          name: "John Doe",
          college: "College A",
          email: "john@example.com",
          roll: "12345",
          branch: "Computer Science",
          year: "First year",
          linkedIn: "https://www.linkedin.com/in/johndoe",
          profilePic: "https://via.placeholder.com/150",
        },
        {
          name: "Jane Smith",
          college: "College B",
          email: "jane@example.com",
          roll: "54321",
          branch: "Electronics",
          year: "Second Year",
          linkedIn: "https://www.linkedin.com/in/janesmith",
          profilePic: "https://via.placeholder.com/150",
        },
      ],
    },
    {
      schoolName: "College B",
      students: [
        {
          name: "Alice Johnson",
          college: "College C",
          email: "alice@example.com",
          roll: "67890",
          branch: "Mechanical Engineering",
          year: "Senior",
          linkedIn: "https://www.linkedin.com/in/alicejohnson",
          profilePic: "https://via.placeholder.com/150",
        },
      ],
    },
  ];

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Navbar />
      <Box
        sx={{
          marginTop: "70px",
          width: "100vw",
          textAlign: "center",
          backgroundImage: `url(../images/perks.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: { xs: "8vw", lg: "2vw", md: "2vw" },
          paddingBottom: "15vw",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 1,
          },
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
            zIndex: 2,
            color: "white",
            padding: { xs: "20px", sm: "20px", md: "50px" },
          }}
        >
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "2.4rem",
                  md: "2.6rem",
                  lg: "2.6rem",
                },
                marginTop: { xs: "20px", md: "80px" },
                fontWeight: "bold",
              }}
              id="hero"
            >
              Student Community Lead
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.1rem",
                  md: "1.2rem",
                  lg: "1.2rem",
                },
                marginTop: "10px",
                fontWeight: "500",
                padding: { xs: "10px", sm: "10px", md: "0px" },
              }}
              id="hero1"
            >
              In order to strengthen the presence of us among college students,
              LYSS Technology has brought the "LYSS Student Community Lead"
              program.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Container>
        <Community />
        <center>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            sx={{
              marginRight: "20px",
              my: 2,
              backgroundColor: "#5656b9",
              borderRadius: "27px",
              width: "fit-content",
              marginTop: "50px",
              color: "white",
            }}
          >
            Apply now
          </Button>
        </center>
        <Typography
          variant="h5"
          align="center"
          marginTop="50px"
          sx={{ fontSize: "1.9rem" }}
        >
          Active Community Leads
        </Typography>
        {schoolData.map((school, index) => (
          <div key={index}>
            <Typography variant="h5" gutterBottom sx={{ marginTop: "60px" }}>
              {school.schoolName}
            </Typography>
            <Divider />
            <Grid container spacing={4}>
              {school.students.map((student, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <Card
                    sx={{
                      height: "100%",
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <CardContent>
                      <Avatar
                        alt={student.name}
                        src={student.profilePic}
                        sx={{
                          width: 80,
                          height: 80,
                          margin: "0 auto",
                          marginBottom: "10px",
                        }}
                      />
                      <Typography
                        variant="h5"
                        component="div"
                        align="center"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        {student.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="center"
                      >
                        {student.email}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="center"
                      >
                        College: {student.college}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="center"
                      >
                        Branch: {student.branch}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="center"
                      >
                        Year: {student.year}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <IconButton
                        href={student.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: "#0072b1" }}
                      >
                        <LinkedInIcon fontSize="large" />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </Container>
      <Footer />

      {/* Apply Dialog */}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Apply for Community Lead</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="profilePic"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth margin="normal">
                  <Button component="label" variant="outlined" color="primary">
                    Upload Profile Picture
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => {
                        field.onChange(e.target.files[0]);
                        setName(e.target.files[0].name);
                        setSize(e.target.files[0].size);
                      }}
                    />
                  </Button>
                  {name && (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <span>{name}</span>
                      <span>{Math.floor(size/1000)}KB</span>
                    </div>
                  )}
                  {error && (
                    <FormHelperText error>{error.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Name"
                  fullWidth
                  margin="normal"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Email"
                  type="email"
                  fullWidth
                  margin="normal"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="college"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="College Name"
                  fullWidth
                  margin="normal"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="branch"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Branch"
                  fullWidth
                  margin="normal"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="year"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Year"
                  fullWidth
                  margin="normal"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="linkedIn"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="LinkedIn Profile URL"
                  fullWidth
                  margin="normal"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              sx={{
                my: 2,
                backgroundColor: "#5656b9",
                borderRadius: "27px",
                width: "83px",
                color: "white",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                my: 2,
                backgroundColor: "#5656b9",
                borderRadius: "27px",
                width: "83px",
                color: "white",
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default Perks;
